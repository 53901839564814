import React from 'react'
import { styled, Paragraph as BaseParagraph } from 'reakit'
import Box from './Box'

const Paragraph = styled(BaseParagraph)`
  color: #828282;
  font-size: 14px;
  letter-spacing: -0.01em;
  line-height: 20px
`

export const ParagraphBox = styled(({ children, ...rest }) => (
  <Box {...rest}>
    <Paragraph color='#000000' fontWeight='500'>
      {children}
    </Paragraph>
  </Box>
))`
  margin-top: 11px;
  text-align: center;
`

export default Paragraph

import React from 'react'
import PartnerScorecardContext from './PartnerScorecardContext'

const withPartnerScorecardContext = (Component) => props => (
  <PartnerScorecardContext.Consumer>
    {({hasResults, loading, results}) => 
      !loading && <Component results={results} hasResults={hasResults} {...props} />
    }
  </PartnerScorecardContext.Consumer>
)

export default withPartnerScorecardContext;
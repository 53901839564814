import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Inline } from 'reakit'
import { Box, ParagraphBox } from '../../components'

export const getSentimentColor = rawNum => {
  const num = parseFloat(rawNum)
  if (num < -0.25) {
    return '#EB5757'
  }
  if (num >= -0.25 && num < 0.25) {
    return '#F2994A'
  }
  return '#76B442'
}

export const getTestAttributeType = rawNum => {
  const num = parseFloat(rawNum)
  if (num < -0.25) {
    return 'negative-keyword'
  }
  if (num >= -0.25 && num < 0.25) {
    return 'neutral-keyword'
  }
  return 'positive-keyword'
}

const Keywords = props => {
  const arr = Object.keys(props.keywords).map(k => (Object.assign({ word: k }, { ...props.keywords[k] })))
  arr.sort((a, b) => b.avg_sentiment - a.avg_sentiment)

  if (arr.length === 0) {
    return (
      <ParagraphBox
        data-testid='no-keywords'
        padding='22px 0'
      >
        No frequently used keywords available during this period
      </ParagraphBox>
    )
  }

  return (
    <Box
      as={Flex}
      flexWrap='wrap'
      fontSize='24px'
      fontWeight={600}
      justifyContent='center'
      letterSpacing='-0.01em'
      padding='12px 24px'
      data-test-id='keywords'
    >
      {arr.map(k => (
        <Inline
          key={k.word}
          color={getSentimentColor(k.avg_sentiment)}
          margin='12px'
          data-test-type={getTestAttributeType(k.avg_sentiment)}
        >
          {k.word}
        </Inline>
      ))}
    </Box>
  )
}

Keywords.propTypes = {
  keywords: PropTypes.object
}

Keywords.defaultProps = {
  keywords: {}
}

export default Keywords

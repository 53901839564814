import React from 'react'
import PropTypes from 'prop-types'
import { styled, Block, Flex, Image } from 'reakit'

import { Button, Container } from '../components'
import { withPartnerScorecardContext } from '../contexts/PartnerScorecardContext'

// Utils
import { EVENTS, trackFromPath } from '../utils/analytics-util'
import searchParamsLink from '../utils/search-params-link'
import { isBlueLabel } from '../utils/isBluelabel'
import { isReviewTrackersCreated } from '../utils/isReviewTrackersCreated'

const urlOrigin = window.location.origin

const WhiteContainer = styled(Block)`
  background: #FFFFFF;
  padding: 22px 0;
  width: 100%;
`

const ContactInfo = styled.div`
  line-height: 6px;
  text-align: right;

  a {
    color: black;
    font-size: 14px;
    text-decoration: underline;
  }

  .light {
    font-weight: 400;
  }
`

const Spacer = styled(Block)`
  flex: 1;
`

const Header = ({ hasResults, results }) => {
  // Verify whitelabel domain
  const isWhitelabelDomain = urlOrigin.includes('wantyourfeedback')
  const hasReturnedResults = hasResults
  const contactEmail = hasReturnedResults ? results.contact_email : ''
  const contactNumber = hasReturnedResults ? results.contact_number : ''
  const isWhitelabel = hasReturnedResults ? results.is_whitelabel : false

  const handleLogoRender = () => {
    let logo_url

    if (hasReturnedResults && isWhitelabel) {
      if (results.logo_url !== null) {
        logo_url = results.logo_url
      } else {
        logo_url = null
      }
    } else if (hasReturnedResults && !isWhitelabel) {
      if (results.logo_url !== null) {
        logo_url = results.logo_url
      } else {
        logo_url = '/img/logo-horizontal-dark.svg'
      }
    } else if (!hasReturnedResults && isWhitelabelDomain) {
      logo_url = null
    } else {
      logo_url = '/img/logo-horizontal-dark.svg'
    }

    return (
      logo_url !== null &&
        <Image
          data-testid='logo-url'
          src={logo_url}
          maxWidth={350}
          height={100}
        />
    )
  }

  const renderStartTrialButton = () => {
    if (isWhitelabelDomain && !hasReturnedResults) return

    if ((isWhitelabel || !isBlueLabel(results)) && hasReturnedResults) {
      return (
        <>
          <h3>
          Contact us to schedule a demo:
          </h3>
        </>
      )
    }

    if (isReviewTrackersCreated(results)) {
      return (
        <>
          <div data-testid='free-trial-button'>
            <Button onClick={() => {
              trackFromPath(EVENTS.SIGNUP)
              window.open(searchParamsLink('https://www.reviewtrackers.com/request-demo/', { package: 'essential' }))
            }}
            >
              Request a demo
            </Button>
          </div>
          <h3>
            Or contact us to learn more:
          </h3>
        </>
      )
    }
  }

  const renderCorrectHeaderContent = () => {
    if (!!contactEmail && !!contactNumber) {
      return (
        <ContactInfo>
          {renderStartTrialButton()}
          <a href={`mailto:${contactEmail}`} data-testid='contact-email'>
            {contactEmail}
          </a>
          <h3 className='light' data-testid='contact-number'>
            {contactNumber}
          </h3>
        </ContactInfo>
      )
    } else if (!!contactEmail && (contactNumber === '' || contactNumber === null)) {
      return (
        <ContactInfo>
          {renderStartTrialButton()}
          <a href={`mailto:${contactEmail}`} data-testid='contact-email'>
            {contactEmail}
          </a>
        </ContactInfo>
      )
    } else if ((contactEmail === '' || contactEmail === null) && !!contactNumber) {
      return (
        <ContactInfo>
          {renderStartTrialButton()}
          <h3 data-testid='contact-number'>
            {contactNumber}
          </h3>
        </ContactInfo>
      )
    }
    return (isReviewTrackersCreated(results) &&
      <div data-testid='free-trial-button'>
        <Button onClick={() => {
          trackFromPath(EVENTS.SIGNUP)
          window.open(searchParamsLink('https://www.reviewtrackers.com/request-demo/', { package: 'essential' }))
        }}
        >
          Request a demo
        </Button>
      </div>
    )
  }

  return (
    <WhiteContainer data-testid='header-container'>
      <Container as={Flex} alignItems='center' flexWrap='wrap'>
        {handleLogoRender()}
        <Spacer />
        {isWhitelabelDomain && !hasReturnedResults ? null : renderCorrectHeaderContent()}
      </Container>
    </WhiteContainer>
  )
}

Header.propTypes = {
  hasResults: PropTypes.bool,
  results: PropTypes.shape({
    contact_email: PropTypes.string,
    contact_number: PropTypes.string,
    is_whitelabel: PropTypes.bool,
    logo_url: PropTypes.string
  })
}

Header.defaultProps = {
  hasResults: false,
  results: {
    contact_email: '',
    contact_number: '',
    is_whitelabel: false,
    logo_url: null
  }
}

export default withPartnerScorecardContext(Header)

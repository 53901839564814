import { styled, Link as BaseLink } from 'reakit'

const Link = styled(BaseLink)`
  color: #097AE6;
  font-size: 14px;
  text-decoration: underline;
  
  &:hover {
    color: #0170DA;
  }
`

export default Link

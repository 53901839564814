import React from 'react'
import {
  Block,
} from 'reakit'

const PastFilter = ({ dateFilters, selectPastFilter, selected }) => (
  <Block>
      <ul className="past-choices">
        <li><span  className="view-past">View Past</span></li>
        {dateFilters.map(dateFilter => (
          <li
            key={dateFilter}
            className={`${selected === dateFilter ? 'selected' : ''}`}
            onClick={() => selectPastFilter(dateFilter)}
          >
            <span>{dateFilter} days</span>
        </li>
        ))}
      </ul>
   </Block>
)

export default PastFilter

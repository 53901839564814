import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { styled, Block, Button, Flex, Image } from 'reakit'
import { Box, Paragraph } from '../../components'
import {EVENTS, trackFromPath} from '../../utils/analytics-util';

const rating = num => {
  const arr = [];
  for(let i = 0; i < num; i++){
    arr.push(<i key={i} className="fas fa-star" />);
  }
  for(let i = num; i < 5; i++){
    arr.push(<i key={i} className="far fa-star" />);
  }
  return arr;
};

const onClickRespond = (permalink) => {
  trackFromPath(EVENTS.RESPOND_CLICKED)
  window.open(permalink);
}

const sourceIconUrl = (source) => {
  if (source === 'Google') {
    return "https://rtx-source-icons.s3.amazonaws.com/logos/google.png";
  }

  if (source === 'Tripadvisor') {
    return "https://rtx-source-icons.s3.amazonaws.com/logos/tripadvisor.png";
  }

  if (source === 'Yelp') {
    return "https://rtx-source-icons.s3.amazonaws.com/logos/yelp.png";
  }

  return null;
}

const truncatedContent = (content) => {
  if (!content) {
    return null;
  }

  if (content.length < 500) {
    return content
  }

  return content.substring(0, 500) + '...'
}

const Wrapper = styled(Box)`
  font-size: 14px;
  letter-spacing: -0.01em;
  margin-top: 11px;
  padding: 20px;

  &:first-child {
    margin-top: 0px;
  }
`

const RespondButton = styled(Button)`
  color: #828282;
  border-color: #E0E0E0;
  border-radius: 4px;
  border-width: 2px;
  font-size: 14px;
  font-weight: 500;
  height: 30px
  padding: 10px;
  width: 161px;
`

const Review = props => (
  <Wrapper data-review-id={props.review_id} data-test-type={`${props.source.toLowerCase()}-review-card`}>
    <Flex alignItems='center'>
      <Flex wrap alignItems='center'>
        <Image
          alt={props.source}
          src={sourceIconUrl(props.source)}
          marginRight='10px'
          maxWidth='57px'
        />
        {
          props.allowRespond &&
          <Block color='#EB5757' fontSize='12px'>
            <i className='fa fa-exclamation-triangle' style={{color: '#EB5757', paddingRight: '5px'}} />
            {moment(props.published_at).startOf('day').fromNow()}
          </Block>
        }
      </Flex>
      <Block flex='1' />
      <Block color='#F2C94C'>{rating(props.rating)}</Block>
    </Flex>
    <Paragraph color='#000000' lineHeight='25px' marginTop='18px' whiteSpace='pre-wrap'>
      {truncatedContent(props.content)}
    </Paragraph>
    {
      props.allowRespond &&
      <Flex alignItems='end' justifyContent='flex-end'>
        <RespondButton onClick={() => onClickRespond(props.permalink)}>
          Respond to review
        </RespondButton>
      </Flex>
    }
  </Wrapper>
)

Review.propTypes = {
  allowRespond: PropTypes.bool,
  source: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  permalink: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
  review_id: PropTypes.string.isRequired
}

Review.defaultProps = {
  allowRespond: false
}

export default Review;

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PartnerScorecardContext from './contexts/PartnerScorecardContext'

import { Container } from './components'
import Header from './Header'
import NotFound from './NotFound'
import ReportCard from './ReportCard'
import StartTrialFooter from './StartTrialFooter'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      hasResults: false,
      isLoading: true,
      results: {}
    }
  }

  componentDidMount () {
    this.fetchScorecardResults()
  }

  fetchScorecardResults = () => {
    const scorecardSlug = window.location.pathname.replace('/', '')
    this.setState({ isLoading: true })
    fetch(`/api/snapshot/${scorecardSlug}/results`)
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response.json())
        }
        return response.json()
      })
      .then(data => {
        this.setState({ isLoading: false, hasResults: true, results: data })
      })
      .catch(err => {
        console.error(err)
        this.setState({ isLoading: false, hasResults: false, error: err.message })
      })
  }

  render () {
    return (
      <Router>
        <PartnerScorecardContext.Provider value={{
          error: this.state.error,
          hasResults: this.state.hasResults,
          loading: this.state.isLoading,
          results: this.state.results
        }}
        >
          <Header />
          <Container>
            <Switch>
              <Route exact path='/:reportId' component={ReportCard} />
              <Route component={NotFound} />
            </Switch>
          </Container>
          <StartTrialFooter />
        </PartnerScorecardContext.Provider>
      </Router>
    )
  }
}

export default App

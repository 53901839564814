import React from 'react'
import { styled, Block, Flex, Image } from 'reakit'
import { Heading, Paragraph, Link } from '../components'

const urlOrigin = window.location.origin

const FlexReverseStack = styled(Flex)`
  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`

const ResponsiveImage = styled(Image)`
  margin: 0 auto;
  object-fit: scale-down;
  width: 257px;
  
  @media (max-width: 500px) {
    margin: 0;
    width: 50%;
  }
`
// Verify whitelabel domain
const isWhitelabelDomain = urlOrigin.includes('wantyourfeedback')

const NotFound = () => (
  <Block
    margin='100px auto 0 auto'
    maxWidth={720}
    padding='0 20px'
  >
    <FlexReverseStack>
      <Block flex={1} paddingTop={10}>
        <Heading
          fontSize='32px'
          lineHeight='41px'
        >
          Sorry, we couldn't find that page!
        </Heading>
        <Paragraph
          fontSize='16px'
          lineHeight='24px'
        >
          You may have used the wrong link or the page has moved.
        </Paragraph>
        {!isWhitelabelDomain &&
          <Paragraph>
          Please contact us at <br />
            <Link href='mailto:support@reviewtrackers.com'>support@reviewtrackers.com</Link>
          </Paragraph>}
      </Block>
      <Block flex={1}>
        <ResponsiveImage src='/img/broken-robot.png' />
      </Block>
    </FlexReverseStack>
  </Block>
)

export default NotFound

import { styled, Button as BaseButton } from 'reakit'

const Button = styled(BaseButton)`
  background: #097AE6;
  border-width: 0;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 10px 20px;
  text-align: center;
  
  &:hover {
    background: #0170DA;
  }
`

export default Button

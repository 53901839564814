/**
 * We want to carry over search params to external links, this is a simple helper to do that.
 */
const searchParamsLink = (link, queryParams = {}) => {
  const qs = Object.keys(queryParams).reduce((query, key) =>
    !query ? `?${key}=${queryParams[key]}` : `${query}&${key}=${queryParams[key]}`
  , window.location.search)
  return link + qs
}

export default searchParamsLink

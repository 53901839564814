import React from 'react'
import PropTypes from 'prop-types'
import { Block, Flex } from 'reakit'
import { Box, Heading } from '../../components'

const healthColor = health => {
  switch(health) {
    case 'above':
      return '#76B442'
    case 'below':
      return '#EB5757'
    default:
      return '#F2994A'
  }
}

const printHealth = health => {
  switch(health) {
    case 'above':
      return 'ABOVE AVERAGE'
    case 'below':
      return 'BELOW AVERAGE'
    default:
      return 'AVERAGE'
  }
}

const ReportStat = props => (
  <Box
    borderBottom={`4px solid ${healthColor(props.health)}`}
    flex='1'
    fontWeight={500}
    margin='5px'
    textAlign='center'
    minWidth='218px'
  >
    <Flex alignItems='center' justifyContent='center' margin='15px 0 10px 0'>
      <Block marginRight='10px'>{props.icon}</Block>
      <Block fontSize='14px' letterSpacing='-0.01em'>{props.heading}</Block>
    </Flex>
    <Heading fontSize='32px' data-test-id={props.testID}>
      {props.value}
    </Heading>
    { props.health !== 'na' &&
      <Block fontSize='12px' margin='16px 0 9px 0' letterSpacing='-0.01em'>
        {printHealth(props.health)}
      </Block>
    }
  </Box>
)
ReportStat.propTypes = {
  health: PropTypes.oneOf(['above', 'below', 'avg', 'na']),
  testID: PropTypes.string
}
ReportStat.defaultTypes = {
  health: 'avg'
}

export default ReportStat

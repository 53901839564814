import { styled, Block } from 'reakit'

const Container = styled(Block)`
  margin: 0 auto;
  max-width: 953px;
  padding: 0 20px;
  width: 100%;
`

export default Container

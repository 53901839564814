import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { styled, Block, Flex, InlineFlex } from 'reakit'
import { Paragraph, ParagraphBox } from '../components'
import { withPartnerScorecardContext } from '../contexts/PartnerScorecardContext'
import NotFound from '../NotFound'

import Keywords from './Keywords'
import ReportHeading from './ReportHeading'
import ReportStat from './ReportStat'
import Review from './Review'
import './ReportCard.css'
import 'url-search-params-polyfill'
import { isReviewTrackersCreated } from '../utils/isReviewTrackersCreated'

const BELOW = 'below'
const SLIGHTLY_BELOW_AVERAGE = 'slightly below average'
const AVERAGE = 'avg'
const SLIGHTLY_ABOVE_AVERAGE = 'slightly above average'
const ABOVE = 'above'

/**
 * Pulls a sample of each review source to beginning of array.
 */
const diversifyBySource = reviews => {
  const results = reviews
    .reduce((agg, r) => {
      agg.primary.map(r => r.source).includes(r.source) ? agg.rest.push(r) : agg.primary.push(r)
      return agg
    }, { primary: [], rest: [] })

  return [...results.primary, ...results.rest]
}

/**
 * Returns top 3 reviews that have content and no response, lowest rating first, and diversifying by source if possible.
 */
const findNeedsAttention = reviews => {
  if (!reviews || reviews.length === 0) {
    return []
  }

  const needsAttention = reviews.filter(r => r && r.content !== null && r.responses.length === 0)
  needsAttention.sort((a, b) => a.rating - b.rating)
  return diversifyBySource(needsAttention).splice(0, 3)
}

/**
 * Returns top 3 reviews that have content, highest rating first, and diversifying by source if possible.
 */
const findHighlights = reviews => {
  if (!reviews || reviews.length === 0) {
    return []
  }

  const highlights = reviews.filter(r => r && r.content !== null && r.rating >= 3)
  highlights.sort((a, b) => b.rating - a.rating)
  return diversifyBySource(highlights).splice(0, 3)
}

const addSource = (obj, source) => {
  obj.source = source
  return obj
}

const aggregateReviews = p => {
  const gReviews = p.google.reviews && Object.values(p.google.reviews).map(r => addSource(r, 'Google'))
  const yReviews = p.yelp.reviews && Object.values(p.yelp.reviews).map(r => addSource(r, 'Yelp'))
  const tReviews = p.ta.reviews && Object.values(p.ta.reviews).map(r => addSource(r, 'Tripadvisor'))

  return [].concat(gReviews, yReviews, tReviews)
}

const getScore = score => {
  switch (score) {
    case BELOW:
      return -2
    case SLIGHTLY_BELOW_AVERAGE:
      return -1
    case AVERAGE:
      return 0
    case SLIGHTLY_ABOVE_AVERAGE:
      return 1
    case ABOVE:
      return 2
    default:
      return 0
  }
}

const getVolHealth = (num_reviews, previous_days) => {
  const options = {
    180: { min: 16, mid_min: 24, mid_max: 31, max: 40 },
    90: { min: 8, mid_min: 12, mid_max: 16, max: 20 },
    60: { min: 6, mid_min: 8, mid_max: 11, max: 14 },
    30: { min: 3, mid_min: 4, mid_max: 6, max: 7 }
  }
  const selected = options[previous_days] === undefined ? options[180] : options[previous_days]

  if (num_reviews < selected.min) {
    return BELOW
  }
  if (num_reviews >= selected.min && num_reviews < selected.mid_min) {
    return SLIGHTLY_BELOW_AVERAGE
  }
  if (num_reviews >= selected.mid_min && num_reviews < selected.mid_max) {
    return AVERAGE
  }
  if (num_reviews >= selected.mid_max && num_reviews < selected.max) {
    return SLIGHTLY_ABOVE_AVERAGE
  }
  return ABOVE
}

const getRatingHealth = rating => {
  if (rating < 3.5) {
    return BELOW
  }
  if (rating >= 3.5 && rating < 4.1) {
    return SLIGHTLY_BELOW_AVERAGE
  }
  if (rating >= 4.1 && rating < 4.3) {
    return AVERAGE
  }
  if (rating >= 4.3 && rating < 4.5) {
    return SLIGHTLY_ABOVE_AVERAGE
  }
  return ABOVE
}

const getRateHealth = rate => {
  if (rate < 0.21) {
    return BELOW
  }
  if (rate >= 0.21 && rate < 0.41) {
    return SLIGHTLY_BELOW_AVERAGE
  }
  if (rate >= 0.41 && rate < 0.51) {
    return AVERAGE
  }
  if (rate >= 0.51 && rate < 0.61) {
    return SLIGHTLY_ABOVE_AVERAGE
  }
  return ABOVE
}

const getTimeHealth = (time, numOfResponse) => {
  if (numOfResponse < 1) {
    return 'na'
  }
  const days = Math.floor(time / 24)
  if (days >= 7) {
    return BELOW
  }
  if (days >= 5 && days < 7) {
    return SLIGHTLY_BELOW_AVERAGE
  }
  if (days >= 3 && days < 5) {
    return AVERAGE
  }
  if (days > 1 && days <= 3) {
    return SLIGHTLY_ABOVE_AVERAGE
  }
  return ABOVE
}

const getResponseTime = (avgTime, numOfResponse) => {
  if (numOfResponse < 1) {
    return 'N/A'
  }
  // Floor so that we can actually show `< 1 day`, otherwise it wouldnt be possible
  const days = Math.floor(avgTime / 24)
  if (days < 1) {
    return '< 1 day'
  }
  if (days === 1) {
    return '1 day'
  }
  return `${days} days`
}

const calculateGrade = (kpis, name, previous_days) => {
  let score = 0

  score += getScore(getVolHealth(kpis.total_reviews, previous_days))
  score += getScore(getRatingHealth(kpis.avg_rating))
  score += getScore(getRateHealth(kpis.avg_response_rate))
  score += getScore(getTimeHealth(kpis.avg_response_time_hours, kpis.total_responded_reviews))
  return handleScore(score)
}

const handleScore = score => {
  switch (score) {
    case 8:
      return 'A+'
    case 7:
      return 'A'
    case 6:
      return 'A-'
    case 5:
      return 'B+'
    case 4:
      return 'B'
    case 3:
      return 'B-'
    case 2:
      return 'C+'
    case 1:
      return 'C'
    case 0:
      return 'C-'
    case -1:
      return 'D+'
    case -2:
      return 'D'
    case -3:
      return 'D-'
    default:
      return 'F'
  }
}

const FlexMobileStack = styled(Flex)`
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const PrintBlock = styled(Block)`
  @media print {
    page-break-inside: avoid;
  }
`

const dateOptions = (date) => {
  const dates = [30, 60, 90, 180]
  const index = dates.indexOf(date)
  return dates.slice(0, index + 1)
}

class ReportCard extends Component {
  constructor (props) {
    super(props)
    this.handleOnSourceFilter = this.handleOnSourceFilter.bind(this)
    this.handleSelectPastFilter = this.handleSelectPastFilter.bind(this)
    this.generateReviews = this.generateReviews.bind(this)
    this.calculateResponseTimeHours = this.calculateResponseTimeHours.bind(this)
    this.calculateAvgResponseRate = this.calculateAvgResponseRate.bind(this)
    this.calculateAverage = this.calculateAverage.bind(this)
    this.responded = this.responded.bind(this)
    this.getInfo = this.getInfo.bind(this)
    this.refreshInfo = this.refreshInfo.bind(this)
    this.setTimePeriod = this.setTimePeriod.bind(this)
    this.setKeywords = this.setKeywords.bind(this)

    const hasResults = props.hasResults
    const reviews = hasResults ? aggregateReviews(props.results) : 0

    const currentUrlParams = new URLSearchParams(window.location.search)
    const timePeriod = currentUrlParams.get('time_period')

    let days = props.results.previous_days !== undefined ? props.results.previous_days : props.previous_days
    if (timePeriod !== null) {
      days = this.setTimePeriod(timePeriod)
    }

    const info = hasResults ? this.refreshInfo(days, null) : {}

    this.state = {
      ...info,
      previous_days: days,
      highlights: findHighlights(reviews),
      needsAttention: findNeedsAttention(reviews),
      source: null
    }
  }

  generateReviews (reviews) {
    this.setState({
      highlights: findHighlights(reviews),
      needsAttention: findNeedsAttention(reviews)
    })
  }

  handleOnSourceFilter (source) {
    const { results } = this.props
    const days = this.state.previous_days

    const overall = source === null ? results.overall : results[source].overall
    const reviews = source === null ? aggregateReviews(results) : Object.values(results[source].reviews)

    this.setState({ ...overall, source: source })
    this.generateReviews(reviews)

    const info = this.refreshInfo(days, source)
    this.setState({
      ...info
    })
  }

  calculateAvgResponseRate (reviews, responded) {
    if (responded.length === 0) {
      return 0
    }

    return responded.length / reviews.length
  }

  calculateResponseTimeHours (responded) {
    if (responded.length === 0) {
      return 0
    }

    const timeDiff = (a, b) => {
      const response = b.responses[0]
      const pa1 = moment(response.published_at)
      const pa2 = moment(b.published_at)
      var duration = moment.duration(pa1.diff(pa2))
      return a + (duration.asHours())
    }
    const time = responded.reduce(timeDiff, 0)

    return (time / responded.length).toFixed(2)
  }

  handleSelectPastFilter (days) {
    const source = this.state.source

    const currentUrlParams = new URLSearchParams(window.location.search)
    currentUrlParams.set('time_period', days)
    this.props.history.push(window.location.pathname + '?' + currentUrlParams.toString())

    const info = this.refreshInfo(days, source)
    this.setState({
      ...info,
      previous_days: days
    })
  }

  calculateAverage (reviews) {
    if (reviews.length === 0) {
      return 0
    }

    const total = reviews.reduce((a, b) => ({ rating: a.rating + b.rating }))
    return (total.rating / reviews.length).toFixed(2)
  }

  responded (reviews) {
    return reviews.filter((review) => {
      return review.responses.length > 0
    })
  }

  getInfo (reviews, responded, previous_days) {
    const { results } = this.props

    const info = {
      avg_rating: this.calculateAverage(reviews),
      avg_response_time_hours: this.calculateResponseTimeHours(responded),
      total_responded_reviews: responded.length,
      avg_response_rate: this.calculateAvgResponseRate(reviews, responded),
      total_reviews: reviews.length,
      keywords: this.setKeywords(results.review_keywords, reviews)
    }

    return {
      ...info,
      grade: reviews.length > 0 ? calculateGrade(info, results.place_info.name, previous_days) : 'F'
    }
  }

  refreshInfo (days, source) {
    const { results } = this.props

    const snapshottedAt = moment(results.snapshotted_at)
    const earliestDay = snapshottedAt.clone().subtract(days, 'days')

    const revs = source === null ? aggregateReviews(results) : results[source].reviews

    const reviews = revs.filter((review) => {
      const publishedAt = moment(review.published_at)
      return publishedAt.isBetween(earliestDay, snapshottedAt)
    })

    this.generateReviews(reviews)

    const responded = this.responded(reviews)
    const info = this.getInfo(reviews, responded, days)

    return info
  }

  setTimePeriod (timePeriod) {
    const times = ['30', '60', '90', '180']
    let time = 180
    if (times.includes(timePeriod)) {
      time = parseInt(timePeriod)
    }

    return time
  }

  setKeywords (keywords, reviews) {
    if (keywords.length === 0 || reviews.length === 0) {
      return {}
    }

    const keywordScores = new Map()
    const reviewKeys = reviews.map(rev => rev.review_id)
    const reviewKeywords = [].concat(...reviewKeys.map(rev => keywords[rev])
      .filter(rev => rev !== undefined))

    // collect up all scores by their short value into a map
    reviewKeywords.forEach(keyword => {
      if (keywordScores.has(keyword.short)) {
        const scores = keywordScores.get(keyword.short)
        scores.push(keyword.sentiment)
        keywordScores.set(keyword.short, scores)
      } else {
        keywordScores.set(keyword.short, [keyword.sentiment])
      }
    })

    const avg = (arr) => {
      return arr.reduce((a, b) => a + b) / arr.length
    }

    const value = (arr) => {
      return arr.length + (avg(arr) * -1)
    }

    let scores = [...keywordScores.entries()]
    scores = scores.sort((a, b) => {
      return value(b[1]) - value(a[1])
    }).slice(0, 6)

    const newKeywords = {}
    scores.forEach(keyword => {
      newKeywords[keyword[0]] = {
        avg_sentiment: keyword[1].reduce((a, b) => a + b) / keyword[1].length,
        total_mentions: keyword[1].length
      }
    })

    return newKeywords
  }

  render () {
    const { hasResults, results } = this.props
    const snapshottedAt = moment(results.snapshotted_at).format('l')
    return (!hasResults
      ? <NotFound />
      : (
        <Block>
          <PrintBlock>
            <ReportHeading
              createdAt={snapshottedAt}
              grade={this.state.grade}
              company={{
                name: results.place_info.name,
                address: results.place_info.address
              }}
              hideAdditionalSources={!isReviewTrackersCreated(results)}
              onSourceFilter={this.handleOnSourceFilter}
              selectPastFilter={this.handleSelectPastFilter}
              selected={this.state.previous_days}
              dateOptions={dateOptions(this.props.results.previous_days)}
              hasReviews={{
                google: results.google.overall && results.google.overall.total_reviews > 0,
                tripadvisor: results.ta.overall && results.ta.overall.total_reviews > 0,
                yelp: results.yelp.overall && results.yelp.overall.total_reviews > 0
              }}
            />
          </PrintBlock>
          <PrintBlock>
            <Flex alignItems='center' margin='38px 0 16px 0'>
              <h3 className='section-heading-alone' data-test-id='day-lookback-disclaimer'>
              PAST {this.state.previous_days} DAYS
              </h3>
              <Block flex={1} />
              <Paragraph data-test-id='starting-from-label'>
                {snapshottedAt}
              </Paragraph>
            </Flex>
            <Flex flexWrap='wrap' justifyContent='space-around'>
              <InlineFlex flexWrap='wrap' justifyContent='center'>
                <ReportStat
                  icon={<i className='far fa-comment' style={{ color: '#097AE6' }} />}
                  heading='Reviews'
                  value={this.state.total_reviews}
                  health={getVolHealth(this.state.total_reviews, this.state.previous_days)}
                  testID='total-reviews-kpi'
                />
                <ReportStat
                  icon={<i className='far fa-star' style={{ color: '#F2C94C' }} />}
                  heading='Average Rating'
                  value={this.state.avg_rating}
                  health={getRatingHealth(this.state.avg_rating)}
                  testID='avg-rating-kpi'
                />
              </InlineFlex>
              <InlineFlex flexWrap='wrap' justifyContent='center'>
                <ReportStat
                  icon={<i className='fa fa-reply' style={{ color: '#9B51E0' }} />}
                  heading='Your Response Rate'
                  value={`${(this.state.avg_response_rate * 100).toFixed(0)}%`}
                  health={getRateHealth(this.state.avg_response_rate)}
                  testID='response-rate-kpi'
                />
                <ReportStat
                  icon={<i className='far fa-clock' style={{ color: '#76B442' }} />}
                  heading='Average Response Time'
                  value={getResponseTime(this.state.avg_response_time_hours, this.state.total_responded_reviews)}
                  health={getTimeHealth(this.state.avg_response_time_hours, this.state.total_responded_reviews)}
                  testID='response-time-kpi'
                />
              </InlineFlex>
            </Flex>
          </PrintBlock>
          <PrintBlock>
            <div>
              <h3 className='section-heading'>FREQUENTLY USED KEYWORDS</h3>
              <h4 className='sub-heading'>Positive and negative words that frequently appeared in your reviews from the past {this.state.previous_days} days</h4>
              <Keywords keywords={this.state.keywords} />
            </div>
          </PrintBlock>
          <FlexMobileStack>
            <div className='review-block' data-testid='review-highlights'>
              <h3 className='section-heading'>REVIEW HIGHLIGHTS</h3>
              <h4 className='sub-heading'>Some of your best reviews from the past {this.state.previous_days} days</h4>
              {this.state.highlights.length > 0 && this.state.highlights.map(r => (
                <Review
                  key={r.review_id}
                  content={r.content}
                  rating={r.rating}
                  source={r.source}
                  permalink={r.permalink}
                  published_at={r.published_at}
                  review_id={r.review_id}
                />
              ))}
              {this.state.highlights.length === 0 && (
                <ParagraphBox
                  data-testid='no-review-highlights'
                  padding='36px 0'
                >
                  No reviews available during this period
                </ParagraphBox>
              )}
            </div>
            <div className='review-block' data-testid='needs-attention'>
              <h3 className='section-heading'>NEEDS ATTENTION</h3>
              <h4 className='sub-heading'>Oldest reviews without a response from the past {this.state.previous_days} days</h4>
              {this.state.needsAttention.length > 0 && this.state.needsAttention.map(r => (
                <Review
                  key={r.review_id}
                  allowRespond
                  content={r.content}
                  rating={r.rating}
                  source={r.source || this.state.source}
                  permalink={r.permalink}
                  published_at={r.published_at}
                  review_id={r.review_id}
                />
              ))}
              {this.state.needsAttention.length === 0 && (
                <ParagraphBox
                  data-testid='no-reviews-need-attention'
                  padding='36px 0'
                >
                  No reviews available during this period
                </ParagraphBox>
              )}
            </div>
          </FlexMobileStack>
        </Block>
      )
    )
  }
}

ReportCard.propTypes = {
  hasResults: PropTypes.bool,
  results: PropTypes.object,
  previous_days: PropTypes.number,
  snapshotted_at: PropTypes.string,
  place_info: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string
  }),
  overall: PropTypes.shape({
    total_reviews: PropTypes.number,
    avg_rating: PropTypes.number,
    avg_response_time_hours: PropTypes.number,
    avg_response_rate: PropTypes.number
  })
}

ReportCard.defaultProps = {
  hasResults: false,
  results: {},
  snapshotted_at: '',
  previous_days: 180,
  place_info: {
    name: '',
    address: ''
  },
  overall: {
    total_reviews: 1,
    avg_rating: 1,
    avg_response_time_hours: 1,
    avg_response_rate: 1
  }
}

export default withPartnerScorecardContext(ReportCard)

const ANALYTICS_URL = '/api/analytics'

export const EVENTS = {
  PAGE_VIEW: 'page_view',
  PRINT: 'print_page',
  RESPOND_CLICKED: 'respond_clicked',
  SIGNUP: 'create_trial_clicked'
}

function logToGoogle (slug, action) {
  if (window.gtag) {
    window.gtag('event', action, {
      page_location: slug
    })
  }
}

function track (slug, eventName) {
  logToGoogle(slug, eventName)
  fetch(ANALYTICS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ event_name: eventName, slug: slug })
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error(response.json())
      }
      return response.json()
    })
    .catch(error => console.error('Analytics Error =\n', error))
}

export function trackFromPath (eventName) {
  const slug = window.location.pathname.substring(1)

  if (slug === '') {
    return
  }

  track(slug, eventName)
}

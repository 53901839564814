import React from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Backdrop,
  Button,
  Block,
  Flex,
  Label as BaseLabel,
  Image,
  Inline,
  InlineBlock,
  InlineFlex,
  Input,
  Popover
} from 'reakit'
import { Box, Heading, Link, Paragraph } from '../../components'

// Utils
import { EVENTS, trackFromPath } from '../../utils/analytics-util'
import searchParamsLink from '../../utils/search-params-link'

import additionalSources from './dropdowngroup.png'
import PastFilter from '../Past'

const MobileInvisibleLink = styled(Link)`
  @media (max-width: 500px), print {
    display: none;
  }
`
const PrintPageLink = () => (
  <MobileInvisibleLink
    cursor='pointer'
    onClick={() => {
      trackFromPath(EVENTS.PRINT)
      window.print()
    }}
  >
    <Inline
      marginRight='4px'
      textDecoration='none'
    >
      <i className='fal fa-print' />
    </Inline>
    Print page
  </MobileInvisibleLink>
)

const GradeLetter = ({ grade }) => {
  let outlineColor
  switch (grade) {
    case 'A+':
    case 'A':
    case 'A-':
    case 'B+':
    case 'B':
    case 'B-':
      outlineColor = '#76B442'
      break
    case 'C+':
    case 'C':
    case 'C-':
      outlineColor = '#F2994A'
      break
    case 'D+':
    case 'D':
    case 'D-':
    case 'F':
      outlineColor = '#EB5757'
      break
    default:
      outlineColor = '#000000'
  }

  const Letter = styled(Block)`
    background-color: #FFFFFF;
    flex: 0 1 auto;
    font-size: 60px;
    font-weight: 600;
    outline: 4px solid ${outlineColor};
    padding: 15px;
    text-align: center;
    user-select: none;
  `
  return (
    <Block marginTop='30px'>
      <Letter data-test-id='report-grade'>{grade}</Letter>
      <Heading fontSize='14px' margin='14px 0'>YOUR GRADE</Heading>
    </Block>
  )
}

const Checkbox = styled(Input)`
  font-size: 14px;
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
`
const Label = styled(BaseLabel)`
  font-size: 14px;
  margin: 0 30px 0 5px;

  &:disabled {
    color: #EFEFEF;
  }
`
const FilterCheckbox = props => (
  <InlineBlock>
    <Checkbox
      {...props}
      id={props.id} name='filter' type='radio'
    />
    <Label
      htmlFor={props.id}
      margin='0 30px 0 5px'
    >
      {props.label}
    </Label>
  </InlineBlock>
)

const AdditionalSources = styled(props => (
  <Popover.Container>
    {popover => (
      <Block {...props} relative>
        <Backdrop background='transparent' as={Popover.Hide} {...popover} />
        <Button
          as={Popover.Toggle} {...popover}
          backgroundColor='#EFEFEF'
          border='none'
          fontSize='14px'
          fontWeight={500}
          padding='8px'
        >
          Additional sources
          <Inline marginLeft='6px'>
            <i className={`far fa-angle-${popover.visible ? 'up' : 'down'}`} />
          </Inline>
        </Button>
        <Popover
          {...popover}
          onClick={() => {
            trackFromPath(EVENTS.SIGNUP)
            window.open(searchParamsLink('https://www.reviewtrackers.com/request-demo/', { package: 'essential' }))
          }}
          left={0}
          paddingLeft={5}
          right={0}
          transform='none'
        >
          <Popover.Arrow />
          <Image
            src={additionalSources}
            alt='additional sources'
            cursor='pointer'
          />
        </Popover>
      </Block>
    )}
  </Popover.Container>
))`
  @media print {
    display: none
  }
`

const ReportHeading = props => (
  <>
    <Block margin='32px 0'>
      <Flex>
        <Heading fontSize='28px'>Reputation Scorecard</Heading>
        <Block flex={1} />
        <PrintPageLink />
      </Flex>
      <Paragraph data-test-id='created-timestamp'>
        Created {props.createdAt}
      </Paragraph>
    </Block>
    <PastFilter
      dateFilters={props.dateOptions}
      selectPastFilter={props.selectPastFilter}
      selected={props.selected}
    />
    <Box>
      <Flex padding='0 24px'>
        <GradeLetter grade={props.grade} />
        <Block alignSelf='center' flex='1' marginLeft='50px'>
          <Heading fontWeight={400} margin='0' data-test-id='location-name'>
            {props.company.name}
          </Heading>
          <Paragraph marginTop='4px' data-test-id='location-address'>
            {props.company.address}
          </Paragraph>
        </Block>
      </Flex>
      <Flex
        alignItems='center'
        borderTop='1px solid #F2F2F2'
        flexWrap='wrap'
        padding='0 12px'
      >
        <Block
          alignSelf='center'
          color='#828282'
          fontWeight={600}
          margin='24px 12px'
        >
          REVIEW SOURCES
        </Block>
        <Block flex='1' />
        <InlineFlex flexWrap='wrap'>
          <Block alignSelf='center' margin='12px'>
            <FilterCheckbox
              id='all'
              label='All'
              data-test-id='all-filter-checkbox'
              defaultChecked
              onClick={() => {
                props.onSourceFilter(null)
              }}
            />
            {props.hasReviews.google &&
              <FilterCheckbox
                id='google'
                label='Google'
                data-test-id='google-filter-checkbox'
                disabled={!props.hasReviews.google}
                onClick={() => {
                  props.onSourceFilter('google')
                }}
              />}
            {props.hasReviews.tripadvisor &&
              <FilterCheckbox
                id='tripadvisor'
                label='Tripadvisor'
                data-test-id='tripadvisor-filter-checkbox'
                disabled={!props.hasReviews.tripadvisor}
                onClick={() => {
                  props.onSourceFilter('ta')
                }}
              />}
            {props.hasReviews.yelp &&
              <FilterCheckbox
                id='yelp'
                label='Yelp'
                data-test-id='yelp-filter-checkbox'
                disabled={!props.hasReviews.yelp}
                onClick={() => {
                  props.onSourceFilter('yelp')
                }}
              />}
          </Block>
          {!props.hideAdditionalSources && <AdditionalSources data-testid='additional-sources' margin='12px' />}
        </InlineFlex>
      </Flex>
    </Box>
  </>
)

GradeLetter.propTypes = {
  grade: PropTypes.string
}

GradeLetter.defaultProps = {
  grade: ''
}

FilterCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string
}

FilterCheckbox.defaultProps = {
  id: '',
  label: ''
}

ReportHeading.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string.isRequired
  }),
  createdAt: PropTypes.string.isRequired,
  dateOptions: PropTypes.array.isRequired,
  grade: PropTypes.oneOf(['A', 'B', 'C', 'D', 'E', 'F']).isRequired,
  hideAdditionalSources: PropTypes.bool,
  hasReviews: PropTypes.shape({
    google: PropTypes.bool,
    tripadvisor: PropTypes.bool,
    yelp: PropTypes.bool
  }),
  onSourceFilter: PropTypes.func,
  selectPastFilter: PropTypes.func,
  selected: PropTypes.number
}

ReportHeading.defaultProps = {
  company: {
    name: ''
  },
  hideAdditionalSources: false,
  hasReviews: {
    google: false,
    tripadvisor: false,
    yelp: false
  },
  onSourceFilter: f => f,
  selectPastFilter: f => f,
  selected: 180
}

export default ReportHeading
